import * as React from 'react';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import type { Entities } from '@inwink/entities/entities';
import type { IPopoverManager } from '@inwink/modals/popovermgr';
import * as assign from 'lodash/assign';
import * as shuffle from 'lodash/shuffle';
import * as moment from 'moment';
import { getPredicate } from '@inwink/expressions';
import { getPropertyValue } from '@inwink/utils/methods/getpropertyvalue';
import type { IDynamicBlocBaseProps } from './common';
import type { States } from '../../services/services';
import { getTimeZoneDate } from "../displaytimezonedate";

export interface ITeaserItemsSort {
    propName: string;
    order?: string;
}

export interface ITeaserItemsBlocCommonProperties {
    filterexpr?: any;
    filterexprBinding?: {
        fields: any;
    };
    filter?: {
        propName: string;
        value: any;
    };
    sort?: ITeaserItemsSort | ITeaserItemsSort[];
    templateProperties?: any;
    allowEmpty?: boolean;
    emptyMessage?: VisualTheme.IAppLabel;
    template?: string | VisualTheme.IItemTemplate | VisualTheme.IItemTemplateConfig;
    minCarouselItems?: number;
    maxItems?: number;
    eventSort?: string;
    disableNavigation?: boolean;
    showRecommandations?: boolean;
}

export interface ITeaserItemsProps extends IDynamicBlocBaseProps {
    className?: string;
    loading?: boolean;
    animateItems?: boolean;
    itemClassName?: string;
    dataKind?: string;
    linkto?: ((item) => (string | { url: string; target: string; }));
    onItemClick?: (item, arg: React.MouseEvent<any>) => void;
    items: any[];
    defaultItemsLayout?: any;
    defaultItemsAlign?: any;
    itemtemplate?: VisualTheme.IItemTemplate | VisualTheme.IItemTemplateConfig;
    itemproperties?: any;
    templateProperties?: any;
    onGetItem?: (props: ITeaserItemsPartProps, item) => any;
    allowEmpty?: boolean;
    minCarouselItems?: number;
    emptyMessage?: string | VisualTheme.IAppLabel;
    emptyComponent?: any;
    templateEditable?: boolean;
    onEditData?: (data: any) => void;
    onDataUpdate?: (data: any) => void;
    renderOnlyContent?: boolean;
    entitySchema?: Entities.IFieldTemplate;
    popovermgr?: IPopoverManager;
    itemsPreviewCount?: number;
    title?: string;
    i18nHelper?: Entities.i18nHelper;
    search?: string;
    showSeeMoreModal?: boolean;
    seeMoreSearchPlaceholder?: string;
    placeholder?: string;
    onCloseSeeMoreModal?: () => void;
    seeMoreSearchFilter?: (item, searchTearm: string) => boolean;
    seeMoreItemsProvider?: () => Promise<any[]>;
    restritedContent?: 'Login_Registered' | 'Login_Registered_AllowNetworking';
}

export interface ITeaserItemsPartProps extends ITeaserItemsProps {
    itemsLayout: string;
    itemsAlign: string;
    itemsLayoutCSS?: string;
    itemsAlignCSS?: string;
    itemsLayoutClassName?: string;
}

export function processExpression(fields, entity) {
    const expression = [];
    fields.forEach((b) => {
        const val = getPropertyValue({ entity: entity }, b.name);
        let fieldExpression: any = null;
        const valType = typeof val;
        // if (valType == "string" || valType == "number" || valType == "boolean"){
        //     fieldExpression = JSON.stringify({ name : b.name, op: b.op || "eq", val : val });
        // }
        if (val) {
            if (valType !== "string" && Array.isArray(val)) {
                fieldExpression = {
                    or: val.map((v) => {
                        return { name: b.name, op: b.op || "eq", val: v };
                    })
                };
            } else {
                fieldExpression = { name: b.name, op: b.op || "eq", val: val };
            }
        }
        expression.push(fieldExpression);
    });

    return expression;
}

function removeDiacritic(val: string) {
    if (val) {
        return val.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "");
    }
}

function sortBy(ap, bp, descSort) {
    let aProp = ap;
    let bProp = bp;
    // On retire les accents des valeurs car cela peut poser problème pour le tri surtout si c'est la premiere lettre. #46433
    if (typeof aProp === "string") {
        aProp = removeDiacritic(aProp.toLowerCase());
    }
    if (typeof bProp === "string") {
        bProp = removeDiacritic(bProp.toLowerCase());
    }

    if (aProp && bProp) {
        if (aProp < bProp) {
            return descSort ? 1 : -1;
        }
        if (aProp > bProp) {
            return descSort ? -1 : 1;
        }
    } else if (!bProp && aProp) {
        return descSort ? -1 : 1;
    } else if (!aProp && bProp) {
        return descSort ? 1 : -1;
    }

    return 0;
}

export function sortItemsBy(
    items: any[],
    currentLanguageCode: string,
    sorting: ITeaserItemsSort,
    selectAndMultiSelectFields?: Entities.IEntityFieldTemplateV3[]) {
    const { order, propName } = sorting;
    const descSort = order === 'desc';

    return items?.sort((a, b) => {
        let aProp = getPropertyValue(a, propName);
        let bProp = getPropertyValue(b, propName);

        const field = selectAndMultiSelectFields?.find(f => f.key === propName);
        if (field) {
            const { valuesList } = field;
            aProp = valuesList.find(v => v.key === aProp)?.labels || aProp;
            bProp = valuesList.find(v => v.key === bProp)?.labels || bProp;
        }

        const proptype = typeof aProp || typeof bProp;
        if (proptype === "object") {
            return sortBy(aProp?.[currentLanguageCode], bProp?.[currentLanguageCode], descSort);
        }

        return sortBy(aProp, bProp, descSort);
    });
}

export function sortItems(
    items: any[],
    currentLanguageCode: string,
    sorting: ITeaserItemsSort | ITeaserItemsSort[],
    entityTemplate?: Entities.IEntityTemplateV3
) {
    if (!sorting) return items;

    const fields = entityTemplate?.fields;
    let selectAndMultiSelectFields: Entities.IEntityFieldTemplateV3[] = [];
    if (fields) {
        selectAndMultiSelectFields = Object.values(fields).filter(({ type }) =>
            type === "SelectList" || type === "MultiSelectList"
        );
    }

    if (Array.isArray(sorting)) {
        return sorting.reduceRight((sortedItems, s) =>
            sortItemsBy(sortedItems, currentLanguageCode, s, selectAndMultiSelectFields), items
        );
    }

    return sortItemsBy(items, currentLanguageCode, sorting, selectAndMultiSelectFields);
}
export function sortEventItems(items: any[], sorting: string, i18n: any) {
    const currentDate = moment().startOf('day');
    const filteredItems = items?.filter((item) => {
        if (!item?.event) return false;
        const startDate = getTimeZoneDate(item.event.startDate, item.event, i18n);
        const endDate = getTimeZoneDate(item.event.endDate, item.event, i18n);

        switch (sorting) {
            case "past":
                return endDate.isBefore(currentDate);
            case "current":
                return startDate.isSameOrBefore(currentDate) && endDate.isSameOrAfter(currentDate);
            case "coming":
                return startDate.isAfter(currentDate);
        }
        return false;
    });
    return filteredItems || [];
}

export function SortAndFilterItems(
    user: States.IAppUserState,
    i18n: Entities.i18nService,
    items: any[],
    properties: ITeaserItemsBlocCommonProperties,
    allowShuffle: boolean,
    entity: any = null,
    entityTemplate?: Entities.IEntityTemplateV3
): any {
    if (!items) return items;
    let sortAndFilterItems = items;
    let expression = properties?.filterexpr;
    if (properties?.filterexprBinding && entity) {
        expression = processExpression(properties.filterexprBinding.fields, entity);
    }

    if (expression) {
        const predicate = getPredicate(expression);
        sortAndFilterItems = sortAndFilterItems.filter((s) => predicate(assign({}, { user: user }, { entity: s }, s)));
    }

    if (properties?.filter?.propName) {
        const filterValue = properties.filter.value;

        sortAndFilterItems = sortAndFilterItems.filter((item) => {
            const prop = item[properties.filter.propName];
            if (prop) {
                let testValue = prop;
                if (prop.key) {
                    testValue = prop.key;
                }
                return testValue === filterValue;
            }

            return false;
        });
    }

    if (properties?.sort) {
        sortAndFilterItems = sortItems(sortAndFilterItems, i18n.currentLanguageCode, properties.sort, entityTemplate);
    } else if (allowShuffle) {
        sortAndFilterItems = shuffle(sortAndFilterItems);
    }

    if (properties?.eventSort) {
        sortAndFilterItems = sortEventItems(sortAndFilterItems, properties.eventSort, i18n);
    }
    // Laisser celui ci à la fin pour ne pas réduire le tableau avant que tous les filtres aient été fait.
    if (properties?.maxItems) {
        sortAndFilterItems = sortAndFilterItems.slice(0, properties.maxItems);
    }

    return sortAndFilterItems;
}
